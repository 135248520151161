import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLayoutList() {
  // Use toast
  const toast = useToast()

  const refLayoutListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'nome'},
    { key: 'actions', label: "Ações" },
  ]
  const currentPage = ref(1)

  const dataMeta = ref({})


  const refetchData = () => {
    refLayoutListTable.value.refresh()
  }

  watch([currentPage], () => {
    refetchData()
  })

  const fetchLayouts = (ctx, callback) => {
    store
      .dispatch('app-layout/fetchLayouts', {
        page: currentPage.value,
        paginate: true,
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching layout list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchLayouts,
    tableColumns,
    currentPage,
    dataMeta,
    refLayoutListTable,

    refetchData

  }
}
