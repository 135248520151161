<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
     
      <div class="m-2">
        <!-- Table Top -->
        <b-row>  
          <!-- Search -->
          <b-col
            cols="12"
            md="3"
          >
            <div class="">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Procurar..."
                debounce="600"
              /> -->
              <b-button
                size="sm"
                variant="primary"
                :to="{ name: 'layout-editor'}"
              >
                <span class="text-nowrap">Adicionar Layout de Email</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refLayoutListTable"
        class="position-relative"
        responsive
        striped
        hover
        :items="fetchLayouts"
        :fields="tableColumns"  
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <!-- Column: User -->
        <template #cell(nome)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap" 
          >
            {{ data.item.nome }}
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="outline-success"
            :to="{ name: 'layout-editor', params: { layoutId: data.item.id } }"
          >
            Abrir/Editar
            <feather-icon
              icon="EyeIcon"
              class="ml-50"
            />
          </b-button>
          <!-- <b-button
            size="sm"
            class="ml-2"
            variant="outline-warning"
          >
            Alterar nome
            <feather-icon
              icon="EditIcon"
              class="ml-50"
            />
          </b-button> -->
          <b-button
            size="sm"
            class="ml-2"
            variant="outline-danger"
            @click="showModalDelete(data.item.id)"
          >
            Excluir
            <feather-icon
              icon="TrashIcon"
              class="ml-50"
            />
          </b-button>
          
        </template>

      </b-table>

      <!-- Paginação -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo de {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.total }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="10"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination, BLink
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useLayoutList from './useLayoutList'
import layoutStoreModule from './layoutStoreModule'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BLink
  },
  setup() {
    const LAYOUT_APP_STORE_MODULE_NAME = 'app-layout'

    // Register module
    if (!store.hasModule(LAYOUT_APP_STORE_MODULE_NAME)) store.registerModule(LAYOUT_APP_STORE_MODULE_NAME, layoutStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LAYOUT_APP_STORE_MODULE_NAME)) store.unregisterModule(LAYOUT_APP_STORE_MODULE_NAME)
    })

    function showModalDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Você deseja prosseguir com a exclusão?', {
          title: 'Confirme sua ação',
          size: 'sm',
          modalClass: 'modal-danger',
          okVariant: 'danger',
          okTitle: 'Sim, confirmar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value == true){
            axios.delete('email-layouts/'+id)
            .then((response) => {
              this.$refs.refLayoutListTable.refresh();
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de registro',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: `Layout excluído com sucesso!`,
                },
              })
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Exclusão de registro',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `Não foi possível excluir seu layout ):`,
                },
              })
            });
          }
        })
    }

    const {
      fetchLayouts,
      tableColumns,
      currentPage,
      dataMeta,
      refLayoutListTable,
      refetchData,

    } = useLayoutList()

    return {

      fetchLayouts,
      tableColumns,
      currentPage,
      dataMeta,
      refLayoutListTable,
      refetchData,
      showModalDelete
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
